import React, { useContext, useEffect, useState } from "react";
import { Checkbox } from "@material-ui/core";
import { CartContext } from "../../contexts/CartContext";

const DonationSetting = ({ donationSetting }) => {
    const cartContext = useContext(CartContext);

    const { setDonationSettings, calculateTotal, donationSettings } = useContext(CartContext);
    const [label, setLabel] = useState(donationSetting.beforeText);
    const [processingFee, setProcessingFee] = useState(0);

    const handleOnChange = (event) => {
        const newSettings = {
            ...donationSetting,
            isSelected: event.target.checked,
            price: calculateProcessingFee(calculateTotal()),
        };

        setDonationSettings(newSettings);
    };

    const calculateProcessingFee = (total) => {
        const fee = parseFloat(donationSettings.rate / 100) * total;
        setProcessingFee(fee.toFixed(2));
        console.log("calculated fee", fee.toFixed(2));
        return fee.toFixed(2);
    };

    const replaceLabel = (text, total, fee) => {
        return text
            .replaceAll("${totalAmount}", total)
            .replaceAll("${donationFee}", donationSettings.rate)
            .replaceAll("[DonationProcessingRate]", `${donationSettings.rate.toFixed(2)}%`)
            .replaceAll("[DonationProcessingAmount]", `$${fee}`)
            .replaceAll("${AdditionalDonationAmount}", `$${fee}`);
    };

    useEffect(() => {
        if (!donationSettings) return;

        const total = calculateTotal();

        if (donationSettings.isSelected) {
            setLabel(replaceLabel(donationSetting.afterText, total.toFixed(2), processingFee));
        } else {
            calculateProcessingFee(total);
            const newTotal = total + parseFloat(processingFee);
            setLabel(replaceLabel(donationSetting.beforeText, newTotal.toFixed(2), processingFee));
        }
    }, [donationSettings, calculateTotal, processingFee]);

    useEffect(() => {
        if (!donationSettings) return;

        if (cartContext.products !== null) {
            // set to default value
            handleOnChange({ target: { checked: true } });
        }
    }, []);

    return (
        <div className="question-form-answer">
            <Checkbox label={label} onChange={handleOnChange} defaultChecked={cartContext.products !== null} /> {label}
        </div>
    );
};

export default DonationSetting;
