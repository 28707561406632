import React, {useContext, useEffect, useState} from "react";
import {CartContext} from "../../contexts/CartContext";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import {FormContext} from "../../contexts/FormContext";

const Cart = (props) => {
    const cartContext = useContext(CartContext);
    const formContext = useContext(FormContext);
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginBottom: "20px"
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        cart: {
            borderTop: '1px solid',
            borderBottom: '1px solid',
            paddingBottom: "15px"
        },
        totals: {
            paddingRight: '15px',
        },
        amounts: {
            marginRight: '15px',
        },
        totalAmounts: {
            marginRight: '34px',
        }
    }));
    const classes = useStyles();
    const [mustSelectProduct, setMustSelectProduct] = useState(false);
    const { donationSettings } = useContext(CartContext);
    const [processingFee, setProcessingFee] = useState(0);
    const [processingFeeLabel, setProcessingFeeLabel] = useState("");

    const calculateProcessingFee = (total) => {
        const fee = parseFloat(donationSettings.rate / 100) * total;
        setProcessingFee(fee.toFixed(2));
        console.log("calculated fee", fee.toFixed(2));
        return fee.toFixed(2);
    };

    useEffect(() => {
        if (!donationSettings) return;

        const total = cartContext.total;

        calculateProcessingFee(total);
        setProcessingFeeLabel(processingFee);

    }, [donationSettings, processingFee]);

    useEffect(() => {
        let products_filtered = Object.keys(cartContext.products).filter(key => key !== "form-fee")
        let products = Object.keys(cartContext.products)
        if (products_filtered.length === 0 && products_filtered.length !== products.length && !cartContext.donation) {
            setMustSelectProduct(true);
        }

        cartContext.recalculateAndSetTotal()
    }, [cartContext])

    return (
        <div id="checkout-form" className={classes.root}>
            <div className={classes.cart}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={2}>
                        <>
                            <Grid item xs={4} display="flex" style={{textAlign: "left"}}>
                                <h3>Item</h3>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "center"}}>
                                <h3>Quantity</h3>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                <div className={classes.amounts}>
                                    <h3>Amount</h3>
                                </div>
                            </Grid>
                        </>
                        {Object.keys(cartContext.products).map(key => (
                            <>
                                <Grid item xs={4} style={{textAlign: "left"}}>
                                    <div dangerouslySetInnerHTML={{__html: cartContext.products[key].name}} />
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    {cartContext.products[key].quantity}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <div className={classes.amounts}>
                                        ${cartContext.products[key].price.toFixed(2)}
                                    </div>
                                </Grid>
                            </>
                        ))}
                        {donationSettings && donationSettings.isSelected && (
                            <>
                                <Grid item xs={4} style={{textAlign: "left"}}>
                                    Processing Fee Cover
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center"}}>

                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <div className={classes.amounts}>
                                        ${processingFeeLabel}
                                    </div>
                                </Grid>
                            </>
                        )}
                        {cartContext.transactionFee && cartContext.transactionFee.type === "amount" && (
                            <>
                                <Grid item xs={4} style={{textAlign: "left"}}>
                                    {cartContext.transactionFee.label}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    {cartContext.products.length}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <div className={classes.amounts}>
                                        ${cartContext.transactionFee.amount.toFixed(2)}
                                    </div>
                                </Grid>
                            </>
                        )}
                        {cartContext.transactionFee && cartContext.transactionFee.type === "percent" && (
                            <>
                                <Grid item xs={4} style={{textAlign: "left"}}>
                                    {cartContext.transactionFee.label}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    {cartContext.products.length}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <div className={classes.amounts}>
                                        ${(cartContext.transactionFee.amount * cartContext.subtotal).toFixed(2)}
                                    </div>
                                </Grid>
                            </>
                        )}
                        {cartContext.donation && (
                            <>
                                <Grid item xs={4} style={{textAlign: "left"}}>
                                    {(cartContext.donation.name !== "") ? cartContext.donation.name : "Donation"}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    {cartContext.donation.quantity}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <div className={classes.amounts}>
                                        ${cartContext.donation.price.toFixed(2)}
                                    </div>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
            <br />
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3} justify-xs-flex-end>
                    <Grid tem xs={4} style={{textAlign: "center"}}/>
                    <Grid tem xs={4} style={{textAlign: "center"}}/>
                    {formContext.form_fees && (
                        <Grid item xs={4} style={{textAlign: "right"}}>
                            <div className={classes.totalAmounts}>
                                <b className={classes.totals}>Subtotal:</b> ${cartContext.total.toFixed(2)}
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={4} style={{textAlign: "right"}}>
                        <div className={classes.totalAmounts}>
                            <b className={classes.totals}>Total:</b> ${cartContext.total.toFixed(2)}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {mustSelectProduct && (
                <Box color="error.main" style={{textAlign: "center"}}>
                    You must select a product before you can check out.
                </Box>
            )}
        </div>
    )
}

export default Cart;